import "./App.css";

import React, { Component } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faCircleCheck,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

import CacheService from "./service/cache/CacheService.js";
import ContactOverlay from "./components/overlays/ContactOverlay.js";
import DateService from "./service/date/DateService.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./components/Header.js";
import HelpOverlay from "./components/overlays/HelpOverlay.js";
import HintsOverlay from "./components/overlays/HintsOverlay.js";
import MenuOverlay from "./components/overlays/MenuOverlay.js";
import PuzzleService from "./service/puzzle/PuzzleService.js";
import PuzzlesOverlay from "./components/overlays/PuzzlesOverlay.js";
import SettingsOverlay from "./components/overlays/SettingsOverlay.js";
import SolutionsOverlay from "./components/overlays/SolutionsOverlay.js";
import StatsOverlay from "./components/overlays/StatsOverlay.js";
import StatsService from "./service/stats/StatsService.js";
import Tile from "./components/Tile.js";
import WordService from "./service/word/WordService.js";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.cacheService = new CacheService();
    this.dateService = new DateService();
    this.puzzleService = new PuzzleService();
    this.wordService = new WordService();
    this.statsService = new StatsService();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      document.getElementById("board").style.width =
        window.innerHeight / 2 + "px";
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // THIS GENERATES THE FOLLOWING WARNING, BUT PUTTING IN COMPONENTDIDMOUNT CAUSES SPEEDY ANIMATIONS
    // Warning: Can't call setState on a component that is not yet mounted.
    // This is a no-op, but it might indicate a bug in your application.
    // Instead, assign to `this.state` directly or define a `state = {};` class property with the desired state in the App component.
    // setInterval(this.animateTiles, 2000);
  }

  colorMap = [];

  state = {
    animations: Array(5)
      .fill()
      .map(() => Object()),
    currentWord: [],
    dayOfYear: 0,
    selectedDay: 0,
    puzzle: [],
    puzzleKey: null,
    modalText: "",
    removedWords: 0,
    showModal: false,
    showContact: false,
    showHelp: false,
    showHints: false,
    showMenu: false,
    showPuzzles: false,
    showSettings: false,
    showStats: false,
    showSolutions: false,
    solution: [],
    theme: null,
  };

  componentDidMount() {
    const dayOfYear = this.dateService.getDayOfYear();

    // const defaultDark = window.matchMedia(
    //   "(prefers-color-scheme: dark)"
    // ).matches;

    let theme = this.cacheService.getTheme();

    // const pathname = window.location.pathname;
    // const puzzleNum = parseInt(pathname.replace("/", ""));

    // const selectedDay =
    //   isNaN(puzzleNum) || puzzleNum > dayOfYear ? dayOfYear : puzzleNum;

    this.setState({
      dayOfYear,
      selectedDay: dayOfYear,
      theme,
    });

    this.initForDay(dayOfYear);
    setInterval(this.animateTiles, 2000);
  }

  validWord = (word) => {
    return this.wordService.validate(word);
  };

  initForDay = (day) => {
    this.toggleAllOff();
    document.title = `WordLock | Puzzle #${day}`;
    const puzzleKey = this.puzzleService.getPuzzleKey(day);
    const puzzle = this.puzzleService.getPuzzleFromKey(puzzleKey);
    const solution = this.cacheService.getGameStateForKey(puzzleKey);
    const removedWords = this.cacheService.getRemovedWordsForKey(puzzleKey);
    const isFirstVisit = this.cacheService.isFirstVisit();

    this.animationCount = 0;

    this.setState({
      animations: Array(5)
        .fill()
        .map(() => Object()),
      puzzle,
      puzzleKey,
      removedWords,
      solution,
      currentWord: [],
      selectedDay: day,
      showHelp: isFirstVisit,
    });
  };

  goToDailyPuzzle = () => {
    this.initForDay(this.state.dayOfYear);
  };

  incrementSelectedDay = () => {
    const { dayOfYear, selectedDay } = this.state;
    if (selectedDay === dayOfYear) return;

    const day = selectedDay + 1;
    this.setState({ selectedDay: day });
    this.initForDay(day);
  };

  decrementSelectedDay = () => {
    const { selectedDay } = this.state;
    if (selectedDay === 0) return;

    const day = selectedDay - 1;
    this.setState({ selectedDay: day });
    this.initForDay(day);
  };

  checkPlayed = (char, col) => {
    return this.state.solution.map((word) => word[col]).includes(char);
  };

  checkSelected = (char, col) => {
    return this.state.currentWord[col] === char;
  };

  getShareText = () => {
    const defaultBlock = this.state.theme === "dark" ? "⬛" : "⬜";
    const blocks = ["🟥", "🟦", "🟧", "🟩", "🟪"];

    let text = "";
    text += "#WordLock";
    text += this.state.selectedDay;
    text += "\r\n";
    text += this.state.solution.length;
    text += "/5 words played";
    text += "\r\n";
    text += this.statsService.getSolutionPoints(this.state.solution);
    text += " points";
    text += "\r\n";
    text += this.state.removedWords;
    text += " word";
    text += this.state.removedWords !== 1 ? "s" : "";
    text += " removed";
    text += "\r\n";
    text += "\r\n";

    const result = Array(25).fill(defaultBlock);

    for (let i = 0; i < this.state.solution.length; i++) {
      for (let j = 0; j < 5; j++) {
        const char = this.state.solution[i][j];
        for (let k = 0; k < this.state.puzzle.length; k++) {
          if (this.state.puzzle[k] === char && k % 5 === j) {
            result[k] = blocks[i];
          }
        }
      }
    }

    for (let i = 0; i < 5; i++) {
      text += result.slice(i * 5, i * 5 + 5).join("") + "\r\n";
    }

    text += "\r\n";
    text += "wordlockgame.com";

    return text;
  };

  handleShare = () => {
    // const text = this.getShareText();
    // try {
    //   const navigator = window.navigator;
    //   navigator
    //     .share({
    //       text,
    //     })
    //     .then((data) => data)
    //     .catch((err) => console.log(err));
    // } catch (error) {
    this.handleCopy();
    // }
  };

  handleCopy = () => {
    const dummy = document.createElement("textarea");
    const text = this.getShareText();

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    this.setState({
      modalText: "copied to clipboard!",
      showModal: true,
    });
    setTimeout(
      () => this.setState({ modalText: "not in word list", showModal: false }),
      2000
    );
  };

  updateCurrentWord = (char, col) => {
    let currentWord = [...this.state.currentWord];
    currentWord[col] = currentWord[col] === char ? undefined : char;
    this.setState({ currentWord });
  };

  removeSolution = (word) => {
    let solution = [...this.state.solution];
    solution.splice(solution.indexOf(word), 1);
    this.cacheService.setGameStateForKey(this.state.puzzleKey, solution);
    this.cacheService.incrementRemovedWordsForKey(this.state.puzzleKey);
    const removedWords = this.state.removedWords + 1;
    this.setState({ solution, removedWords });
  };

  updateSolution = () => {
    let { currentWord, solution } = this.state;

    currentWord = currentWord.join("");

    if (this.wordService.validate(currentWord)) {
      solution.push(currentWord);
      currentWord = [];
      this.setState({ currentWord, solution });
      this.cacheService.setGameStateForKey(this.state.puzzleKey, solution);
      if (solution.length === 5) {
        const modalText =
          this.state.removedWords === 0 ? "perfect!" : "solved!";
        this.setState({ modalText, showModal: true });
        setTimeout(
          () => this.setState({ showModal: false, showStats: true }),
          2000
        );
      }
    } else {
      this.setState({ modalText: "not in word list", showModal: true });
      setTimeout(() => this.setState({ showModal: false }), 2000);
    }
  };

  animationCount = 0;

  animateTiles = () => {
    let {
      animations,
      solution,
      showContact,
      showHelp,
      showHints,
      showMenu,
      showModal,
      showPuzzles,
      showSettings,
      showSolutions,
      showStats,
    } = this.state;

    if (
      showContact ||
      showHelp ||
      showHints ||
      showMenu ||
      showModal ||
      showPuzzles ||
      showSettings ||
      showSolutions ||
      showStats
    )
      return;

    for (let word of solution) {
      let col = 0;
      for (let char of word) {
        animations[col][char] = false;
        col++;
      }
    }

    if (solution.length < 5) {
      this.setState({ animations });
      return;
    }

    const word = solution[this.animationCount];

    let col = 0;
    for (let char of word) {
      animations[col][char] = true;
      col++;
    }

    if (this.animationCount < solution.length - 1) {
      this.animationCount++;
    } else {
      this.animationCount = 0;
    }

    this.setState({ animations });
  };

  shouldAnimate = (char, col) => {
    const { animations, showModal, solution } = this.state;

    if (solution.length < 5 || showModal) return false;

    return animations[col][char];
  };

  toggleAllOff = () => {
    this.setState({
      showContact: false,
      showHelp: false,
      showHints: false,
      showMenu: false,
      showSettings: false,
      showStats: false,
      showPuzzles: false,
      showSolutions: false,
      showModal: false,
    });
  };

  toggleShowContact = () => {
    this.toggleAllOff();
    this.setState({ showContact: !this.state.showContact });
  };

  toggleShowHelp = () => {
    this.toggleAllOff();
    this.setState({ showHelp: !this.state.showHelp });
  };

  toggleShowHints = () => {
    this.toggleAllOff();
    this.setState({ showHints: !this.state.showHints });
  };

  toggleShowMenu = () => {
    this.toggleAllOff();
    this.setState({ showMenu: !this.state.showMenu });
  };

  toggleShowPuzzles = () => {
    this.toggleAllOff();
    this.setState({ showPuzzles: !this.state.showPuzzles });
  };

  toggleShowSettings = () => {
    this.toggleAllOff();
    this.setState({ showSettings: !this.state.showSettings });
  };

  toggleShowStats = () => {
    this.toggleAllOff();
    this.setState({ showStats: !this.state.showStats });
  };

  toggleShowSolutions = () => {
    this.toggleAllOff();
    this.setState({ showSolutions: !this.state.showSolutions });
  };

  toggleTheme = () => {
    const theme = this.state.theme === "dark" ? "light" : "dark";
    this.cacheService.setTheme(theme);
    this.setState({ theme });
  };

  render() {
    const boardStyle = { width: window.innerHeight / 2 + "px" };

    const { selectedDay, dayOfYear, solution, puzzleKey, puzzle } = this.state;

    return (
      <div className="app" data-theme={this.state.theme}>
        <Header
          toggleShowHelp={this.toggleShowHelp}
          toggleShowHints={this.toggleShowHints}
          toggleShowMenu={this.toggleShowMenu}
          toggleShowStats={this.toggleShowStats}
          toggleShowSettings={this.toggleShowSettings}
          goToDailyPuzzle={this.goToDailyPuzzle}
        />
        <div className="game">
          {this.state.showHelp && (
            <HelpOverlay toggleShowHelp={this.toggleShowHelp} />
          )}
          {this.state.showHints && (
            <HintsOverlay
              puzzleKey={puzzleKey}
              puzzle={puzzle}
              solution={solution}
              toggleShowHints={this.toggleShowHints}
            />
          )}
          {this.state.showMenu && (
            <MenuOverlay
              toggleShowMenu={this.toggleShowMenu}
              toggleShowStats={this.toggleShowStats}
              toggleShowSettings={this.toggleShowSettings}
              toggleShowContact={this.toggleShowContact}
            />
          )}
          {this.state.showPuzzles && (
            <PuzzlesOverlay
              toggleShowPuzzles={this.toggleShowPuzzles}
              puzzleKey={puzzleKey}
              initForDay={this.initForDay}
            />
          )}
          {this.state.showStats && (
            <StatsOverlay
              toggleShowStats={this.toggleShowStats}
              toggleShowPuzzles={this.toggleShowPuzzles}
              handleShare={this.handleShare}
              theme={this.state.theme}
            />
          )}
          {this.state.showSolutions && (
            <SolutionsOverlay
              toggleShowSolutions={this.toggleShowSolutions}
              puzzleKey={this.state.puzzleKey}
              handleShare={this.handleShare}
              theme={this.state.theme}
            />
          )}
          {this.state.showSettings && (
            <SettingsOverlay
              theme={this.state.theme}
              toggleTheme={this.toggleTheme}
              toggleShowSettings={this.toggleShowSettings}
            />
          )}
          {this.state.showContact && (
            <ContactOverlay toggleShowContact={this.toggleShowContact} />
          )}
          <div className="game-info">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="icon"
              style={{
                float: "left",
                color: selectedDay > 0 ? "var(--text-primary)" : "lightgray",
              }}
              onClick={() => this.decrementSelectedDay()}
            />
            <button className="game-button">
              <span
                style={{ cursor: "pointer" }}
                onClick={this.toggleShowPuzzles}
              >
                {selectedDay === dayOfYear ? "Daily" : "Archive"} Puzzle #
                {this.state.selectedDay}
              </span>{" "}
              {this.state.solution.length === 5 && (
                <FontAwesomeIcon icon={faCircleCheck} color="green" />
              )}
            </button>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="icon"
              style={{
                float: "right",
                color:
                  selectedDay < dayOfYear ? "var(--text-primary)" : "lightgray",
              }}
              onClick={() => this.incrementSelectedDay()}
            />
          </div>
          <div id="board-container">
            {this.state.showModal && (
              <div className="modal">{this.state.modalText}</div>
            )}
            <div id="board" style={boardStyle}>
              {this.state.puzzle.map((char, index) => {
                const col = index % 5;
                return (
                  <Tile
                    char={char}
                    col={col}
                    key={index}
                    played={this.checkPlayed(char, col)}
                    selected={this.checkSelected(char, col)}
                    updateCurrentWord={this.updateCurrentWord}
                    animate={this.shouldAnimate(char, col)}
                  />
                );
              })}
            </div>
          </div>
          <div className="button-container">
            {this.state.solution.length < 5 && (
              <button
                disabled={
                  this.state.currentWord.filter((char) => char !== undefined)
                    .length < 5
                }
                className="play-button"
                onClick={this.updateSolution}
              >
                play
              </button>
            )}
            {this.state.solution.length === 5 && (
              <button
                className="solution-button"
                onClick={() => this.toggleShowSolutions()}
              >
                view solutions
              </button>
            )}
          </div>
          <div className="solution-container">
            {this.state.solution.length === 0 &&
              this.state.removedWords === 0 && (
                <div className="solution-info">
                  you haven't played any words yet
                </div>
              )}
            {(this.state.removedWords > 0 ||
              this.state.solution.length > 0) && (
              <div style={{ paddingBottom: "10px" }}>
                Total Points: {this.statsService.getSolutionPoints(solution)}
                <br />
                Removed Words:{" "}
                <span
                  style={{
                    color:
                      this.state.removedWords > 0
                        ? "red"
                        : "var(--text-primary)",
                  }}
                >
                  {this.state.removedWords}
                </span>
              </div>
            )}
            {this.state.solution.map((word) => {
              const points = this.statsService.getWordPoints(word);
              return (
                <div className="solution-row" key={word}>
                  <div className="solution">
                    {word}{" "}
                    <span
                      style={{
                        color: "var(--text-secondary)",
                        textTransform: "none",
                      }}
                    >
                      {points}
                    </span>
                  </div>
                  {this.state.solution.length < 5 && (
                    <div className="solution-remove">
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="icon"
                        onClick={() => this.removeSolution(word)}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
