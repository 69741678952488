import React, { Component } from "react";

export default class Countdown extends Component {
  state = {
    nextPuzzleText: "",
  };

  componentDidMount() {
    const nextPuzzleText = this.getNextPuzzleText();
    this.setState({ nextPuzzleText });
    setInterval(this.setNextPuzzleText, 1000);
  }

  setNextPuzzleText = () => {
    const nextPuzzleText = this.getNextPuzzleText();
    this.setState({ nextPuzzleText });
  };

  getNextPuzzleText = () => {
    const today = new Date();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const diff = (tomorrow - today) / 1000;

    const hours = Math.floor(diff / (60 * 60));
    const minutes = Math.floor((diff - hours * 60 * 60) / 60);
    const seconds = Math.floor(diff - hours * 60 * 60 - minutes * 60);

    function pad(n) {
      return (n < 10 ? "0" : "") + n;
    }

    return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
  };

  render() {
    return (
      <table>
        <thead>
          <tr>
            <th style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              next puzzle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              {this.state.nextPuzzleText}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
