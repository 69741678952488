import CacheService from "../cache/CacheService.js";
import DateService from "../date/DateService.js";
import PuzzleService from "../puzzle/PuzzleService.js";
import WordService from "../word/WordService.js";

class StatsService {
  constructor() {
    this.dateService = new DateService();
    this.cacheService = new CacheService();
    this.puzzleService = new PuzzleService();
    this.wordService = new WordService();
    // sorted by frequency
    this.sgbWords = this.wordService.getSgbWords();
  }

  // 0.0 - 1.0
  getWordScore = (word) => {
    const idx = this.sgbWords.indexOf(word);
    // in wordle list but not sgb
    if (idx === -1) return 0;
    return idx / this.sgbWords.length;
  };

  // 1 - 5
  getWordPoints = (word) => {
    const score = this.getWordScore(word);
    // in wordle list but not sgb
    if (score === 0) return 5;
    return Math.ceil(score * 5);
  };

  getSolutionPoints = (solution) => {
    if (!solution) return 0;
    let points = 0;
    for (let word of solution) {
      points += this.getWordPoints(word);
    }
    return points;
  };

  getStats = () => {
    const todayIndex = this.dateService.getDayOfYear();

    const gameState = this.cacheService.getGameState();
    const gameStateEntries = Object.entries(gameState).filter(
      (game) => this.puzzleService.getPuzzleIndex(game[0]) <= todayIndex
    );

    const playedPuzzleKeys = gameStateEntries.map((game) => game[0]);
    const solvedPuzzleKeys = gameStateEntries
      .filter((game) => game[1].length === 5)
      .map((game) => game[0]);

    const played = playedPuzzleKeys.length;
    const solved = solvedPuzzleKeys.length;

    let solvedPuzzleIndexes = solvedPuzzleKeys.map((key) =>
      this.puzzleService.getPuzzleIndex(key)
    );
    solvedPuzzleIndexes.sort((a, b) => a - b);

    let streak = 0;
    let currStreak = 0;
    let maxStreak = 0;
    let totalPoints = 0;
    let bestWord;
    let bestWordPoints = 0;
    let bestGameKey;
    let bestGameIndex;
    let bestGamePoints = 0;

    if (solved > 0) {
      let stack = [];
      for (let puzzleIndex of solvedPuzzleIndexes) {
        const puzzleKey = this.puzzleService.getPuzzleKey(puzzleIndex);
        const solution = gameState[puzzleKey];
        const points = this.getSolutionPoints(solution);
        totalPoints += points;
        if (
          points > bestGamePoints ||
          (points === bestGamePoints && Math.random() > 0.5) // 50% chance of replacing word with same score
        ) {
          bestGameKey = puzzleKey;
          bestGameIndex = puzzleIndex;
          bestGamePoints = points;
        }
        for (let word of solution) {
          const wordPoints = this.getWordPoints(word);
          if (
            wordPoints > bestWordPoints ||
            (wordPoints === bestWordPoints && Math.random() > 0.5) // 50% chance of replacing word with same score
          ) {
            bestWord = word;
            bestWordPoints = wordPoints;
          }
        }
        if (stack[stack.length - 1] === puzzleIndex - 1) {
          stack.push(puzzleIndex);
          currStreak = stack.length;
          maxStreak = Math.max(maxStreak, currStreak);
        } else {
          stack = [puzzleIndex];
          currStreak = stack.length;
          maxStreak = Math.max(maxStreak, currStreak);
        }
      }
      if (stack[stack.length - 1] === todayIndex) streak = currStreak;
    }

    const removedWords = this.cacheService.getRemovedWords();

    const numsRemoved = [];

    const removedCounts = {
      0: 0,
      "<5": 0,
      "<10": 0,
      "10+": 0,
    };

    for (let key of playedPuzzleKeys) {
      const removed = removedWords[key] || 0;

      numsRemoved.push(removed);

      if (removed === 0) {
        removedCounts["0"]++;
      } else if (removed < 5) {
        removedCounts["<5"]++;
      } else if (removed < 10) {
        removedCounts["<10"]++;
      } else {
        removedCounts["10+"]++;
      }
    }

    let averageRemovedWords =
      numsRemoved.length > 0
        ? numsRemoved.reduce((a, b) => a + b) / numsRemoved.length
        : 0;

    return {
      played,
      solved,
      streak,
      maxStreak,
      removedCounts,
      averageRemovedWords,
      totalPoints,
      bestWord,
      bestWordPoints,
      bestGameKey,
      bestGameIndex,
      bestGamePoints,
    };
  };
}

export default StatsService;
