import puzzles from "./puzzles.json";

class PuzzleService {
  puzzles = puzzles;

  getPuzzleIndex(key) {
    return puzzles.indexOf(key);
  }

  getPuzzleKey(index) {
    if (index > puzzles.length) index = index % puzzles.length;
    return puzzles[index];
  }

  getPuzzleFromKey(key) {
    let puzzle = [];
    for (let i = 0; i < 5; i++) {
      for (let j = i; j < key.length; j += 5) {
        puzzle.push(key[j]);
      }
    }
    return puzzle;
  }
}

export default PuzzleService;
