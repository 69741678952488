import Trie from "./Trie.js";
import sgbWords from "./sgb-words.json";
import words from "./words.json";

const WORD_LENGTH = 5;

class WordService {
  constructor() {
    this.trie = new Trie();
    for (let word of words) {
      this.trie.insert(word);
    }
  }

  getWords = () => {
    return words;
  };

  // ordered by frequency
  getSgbWords = () => {
    return sgbWords;
  };

  getSolutions = (puzzleKey) => {
    let cols = [];

    for (let i = 0; i < puzzleKey.length; i += WORD_LENGTH) {
      cols.push(puzzleKey.slice(i, i + WORD_LENGTH));
    }

    let combinations = [];

    for (let a = 0; a < WORD_LENGTH; a++) {
      for (let b = 0; b < WORD_LENGTH; b++) {
        for (let c = 0; c < WORD_LENGTH; c++) {
          for (let d = 0; d < WORD_LENGTH; d++) {
            for (let e = 0; e < WORD_LENGTH; e++) {
              let combination = [
                cols[0][a],
                cols[1][b],
                cols[2][c],
                cols[3][d],
                cols[4][e],
              ];

              combinations.push(combination);
            }
          }
        }
      }
    }

    combinations = combinations.map((combination) => combination.join(""));

    combinations = combinations.filter((combination) =>
      this.trie.search(combination)
    );

    function validate(words) {
      for (let i = 0; i < WORD_LENGTH; i++) {
        if (new Set(words.map((word) => word[i])).size !== words.length)
          return false;
      }
      return true;
    }

    let res = [];

    function backtrack(curr, start) {
      if (curr.length === 5) {
        res.push([...curr]);
        return;
      }

      for (let i = start; i < combinations.length; i++) {
        const word = combinations[i];
        if (validate([...curr, word])) {
          curr.push(word);
          backtrack(curr, i + 1);
          curr.pop();
        }
      }
    }

    backtrack([], 0);

    return res;
  };

  getPossibleWords = (puzzleKey, solution) => {
    let cols = [];

    for (let i = 0; i < puzzleKey.length; i += WORD_LENGTH) {
      cols.push(puzzleKey.slice(i, i + WORD_LENGTH));
    }

    for (let word of solution) {
      for (let i = 0; i < WORD_LENGTH; i++) {
        let col = cols[i];
        cols[i] = col.replace(word[i], "");
      }
    }

    let combinations = [];

    for (let a = 0; a < cols[0].length; a++) {
      for (let b = 0; b < cols[0].length; b++) {
        for (let c = 0; c < cols[0].length; c++) {
          for (let d = 0; d < cols[0].length; d++) {
            for (let e = 0; e < cols[0].length; e++) {
              let combination = [
                cols[0][a],
                cols[1][b],
                cols[2][c],
                cols[3][d],
                cols[4][e],
              ];

              combinations.push(combination);
            }
          }
        }
      }
    }

    combinations = combinations.map((combination) => combination.join(""));

    combinations = combinations.filter((combination) =>
      this.trie.search(combination)
    );

    function validate(words) {
      for (let i = 0; i < WORD_LENGTH; i++) {
        if (new Set(words.map((word) => word[i])).size !== words.length)
          return false;
      }
      return true;
    }

    let possibleWords = new Set();

    function backtrack(curr, start) {
      if (curr.length === 5) {
        for (let word of [...curr]) {
          possibleWords.add(word);
        }
        return;
      }

      for (let i = start; i < combinations.length; i++) {
        const word = combinations[i];
        if (validate([...curr, word])) {
          curr.push(word);
          backtrack(curr, i + 1);
          curr.pop();
        }
      }
    }

    backtrack([...solution], 0);

    for (let word of solution) {
      possibleWords.delete(word);
    }

    possibleWords = Array.from(possibleWords).sort();

    return possibleWords;
  };

  validate = (word) => {
    return this.trie.search(word);
  };
}

export default WordService;
