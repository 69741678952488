import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { Component } from "react";
import { faShareNodes, faXmark } from "@fortawesome/free-solid-svg-icons";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Countdown from "../Countdown.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Referrals from "../Referrals.js";
import StatsService from "../../service/stats/StatsService.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

export default class StatsOverlay extends Component {
  constructor(props) {
    super(props);
    this.statsService = new StatsService();
  }

  state = {
    stats: {
      averageRemovedWords: 0,
      removedCounts: {
        0: 0,
        "<5": 0,
        "<10": 0,
        "10+": 0,
      },
      totalPoints: 0,
      bestWord: undefined,
      bestWordPoints: 0,
      bestGameIndex: undefined,
      bestGamePoints: 0,
    },
  };

  componentDidMount() {
    const stats = this.statsService.getStats();
    this.setState({ stats });
  }

  render() {
    const { theme } = this.props;

    const getColor = () => {
      return theme === "dark" ? "#ffffff" : "#000000";
    };

    const getBarColor = () => {
      return theme === "dark" ? "#404040" : "lightgray";
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: "y",
      plugins: {
        legend: { display: false },
        datalabels: {
          color: getColor(),
          font: {
            family: "Courier New",
            weight: "bold",
          },
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: getColor(),
            font: {
              family: "Courier New",
            },
          },
        },
        x: {
          display: false,
        },
      },
      tooltips: {
        enabled: false,
      },
    };

    const { removedCounts, averageRemovedWords, totalPoints } =
      this.state.stats;

    const labels = Object.keys(removedCounts);

    const data = {
      labels,
      datasets: [
        {
          label: "Removed Words",
          data: Object.values(removedCounts),
          backgroundColor: getBarColor(),
        },
      ],
    };

    return (
      <div className="overlay">
        <div className="overlay-content">
          <FontAwesomeIcon
            icon={faXmark}
            className="icon xmark"
            onClick={this.props.toggleShowStats}
          />
          <h1 style={{ fontSize: "1.5em", margin: 0, padding: "10px" }}>
            total points: {totalPoints}
          </h1>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  played
                </td>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {this.state.stats.played}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  solved
                </td>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {this.state.stats.solved} (
                  {this.state.stats.solved === 0
                    ? 0
                    : Math.floor(
                        (this.state.stats.solved / this.state.stats.played) *
                          100
                      )}
                  %)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  streak
                </td>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {this.state.stats.streak}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  max streak
                </td>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {this.state.stats.maxStreak}
                </td>
              </tr>
              {this.state.stats.solved > 0 && (
                <>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        paddingRight: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      best word
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {this.state.stats.bestWord.toUpperCase()}{" "}
                      <span style={{ color: "var(--text-secondary" }}>
                        {this.state.stats.bestWordPoints}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        paddingRight: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      best game
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      #{this.state.stats.bestGameIndex}{" "}
                      <span style={{ color: "var(--text-secondary" }}>
                        {this.state.stats.bestGamePoints}
                      </span>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          {/* {Object.keys(removedWordsByDay).length > 0 && ( */}
          <>
            <h2 style={{ fontSize: "1em", margin: 0, padding: "10px" }}>
              average removed words: {averageRemovedWords.toFixed(1)}
            </h2>
            <div style={{ padding: "0px 40px", maxHeight: "200px" }}>
              <Bar options={options} data={data} plugins={[ChartDataLabels]} />
            </div>
          </>
          {/* )} */}

          <div
            style={{ display: "flex", gap: "15px", justifyContent: "center" }}
          >
            <button className="share-button" onClick={this.props.handleShare}>
              <FontAwesomeIcon icon={faShareNodes} className="icon" /> share
            </button>
            <button
              className="puzzle-button"
              onClick={this.props.toggleShowPuzzles}
            >
              keep playing
            </button>
          </div>

          <div style={{ padding: "10px" }}>
            <Countdown />
          </div>

          <div style={{ padding: "10px" }}>
            <Referrals />
          </div>
        </div>
      </div>
    );
  }
}
